// CSS
import './css/layout.scss';

import './sentry';

if (process.env.NODE_ENV === 'production') {
  require('overlayscrollbars');

  document.addEventListener('DOMContentLoaded', function () {
    //The first argument are the elements to which the plugin shall be initialized
    //The second argument has to be at least a empty object or a object with your desired options
    OverlayScrollbars(document.querySelectorAll('body'), {
      className: 'os-theme-dark',
    });
  });
}

import './js/techvideos';
