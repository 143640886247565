const ActiveClass = 'active';

function getVideos() {
  return document.getElementsByClassName('tech-video');
}

function isVideoActive(video) {
  return video.classList.contains(ActiveClass);
}

function showVideo(video) {
  video.classList.add(ActiveClass);
  document.getElementById(video.dataset.controlId)?.classList.add(ActiveClass);
}

function hideVideo(video) {
  video.classList.remove(ActiveClass);
  document.getElementById(video.dataset.controlId).classList.remove(ActiveClass);
}

function showNextVideo() {
  const videos = document.getElementsByClassName('tech-video');

  for (let i = 0; i < videos.length; i++) {
    const video = videos.item(i);
    if (!isVideoActive(video)) {
      // When the video is not active, skip it
      continue;
    }

    // Remove current video from display
    hideVideo(video);

    // Show the next video
    const isLast = i === (videos.length - 1);
    const newVideo = videos.item(isLast ? 0 : i + 1);
    showVideo(newVideo);

    if (!isLast) {
      // When not the last video, start the playback of the next video
      newVideo.currentTime = 0;
      newVideo.play()?.catch(e => console.error(e));
    }

    return;
  }
}

function handleClick(button) {
  // Remove button focus
  button.blur();

  // Retrieve video details
  const newVideo = document.getElementById(button.dataset.videoId);
  if (!newVideo) {
    return;
  }

  // The selected video is already active
  if (isVideoActive(newVideo)) {
    newVideo.play();
    return;
  }

  // Stop any running video
  const videos = document.getElementsByClassName('tech-video');
  for (const video of videos) {
    video.pause();
    hideVideo(video);
  }

  // Activate the video
  showVideo(newVideo);
  newVideo.currentTime = 0;
  newVideo.play();
}

document.addEventListener('DOMContentLoaded', function () {
  // Look for the videos
  const videos = getVideos();
  if (videos.length === 0) {
    return;
  }

  // Show the first video by default
  showVideo(videos.item(0));

  // Add ended listener for the available videos
  for (const video of videos) {
    video.addEventListener('ended', () => showNextVideo());
  }

  // Register button handlers
  for (const button of document.getElementsByClassName('technical-video-selector')) {
    button.addEventListener('click', (e) => handleClick(e.target));
  }
});
